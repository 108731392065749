<template>
  <div class="container">
    <!-- 按条件筛选查找数据 -->
    <el-form ref="formInline" :inline="true" :model="formInline" small="size">
      <el-form-item label="医生团队名称：">
        <el-select v-model="formInline.teamCode" filterable>
          <el-option
            v-for="item in doctorAllTeamList"
            :key="item.teamCode"
            :value="item.teamCode"
            :label="item.teamName"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="科室:" prop="deptCode">
        <el-select
          v-model="formInline.parentDeptCode"
          placeholder="请选择科室"
          filterable
          style="width: 45%"
        >
          <el-option
            v-for="item in parentDepartmentList"
            :key="item.deptCode"
            :value="item.deptCode"
            :label="item.deptName"
          />
        </el-select>
        <el-select
          v-model="formInline.deptCode"
          placeholder=""
          filterable
          style="width: 45%"
        >
          <el-option
            v-for="item in departmentList"
            :key="item.deptCode"
            :label="item.deptName"
            :value="item.deptCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="医生姓名：">
        <el-input
          v-model.trim="formInline.doctorName"
          v-check-permission
          placeholder="请输入医生姓名"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData()"> 查询 </el-button>
        <el-button type="primary" @click="queryClear()"> 重置 </el-button>
      </el-form-item>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="orderListData"
      border
      class="width-96"
    >
      <el-table-column type="index" label="序号" width="50" />
      <el-table-column prop="teamName" label="医生团队" align="center" />
      <el-table-column prop="deptName" label="科室" align="center" />
      <el-table-column prop="mobile" label="手机号" align="center" />
      <el-table-column prop="doctorName" label="医生名称" align="center" />
      <el-table-column
        label="操作"
        show-overflow-tooltip
        width="200"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            v-if="$checkPermission('ROSTERREVISION', $route)"
            type="text"
            size="mini"
            @click="dataOperation(scope.row)"
          >
            调整排班
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页功能模块 -->
    <div class="padding-30">
      <el-pagination
        background
        :page-sizes="[10, 20, 50, 100, 200]"
        :current-page="pageInfo.page"
        :page-size="pageInfo.size"
        layout="->,total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: 'ServiceManageList',
  data() {
    return {
      parentDepartmentList: [], // 一级科室列表
      departmentList: [{ deptCode: 'all', deptName: '全部' }], // 二级科室列表
      loading: false,
      formInline: {
        doctorName: '',
        teamCode: 'all',
        parentDeptCode: 'all',
        deptCode: 'all',
      },
      pageInfo: {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      },
      orderListData: [], // 服务列表
    };
  },
  computed: {
    ...mapState('basicDictionary', {
      allDepartmentList: (state) => state.allDepartmentList,
    }),
    ...mapState('doctorTeam', {
      doctorTeamList: (state) => state.allDoctorList,
    }),
    doctorAllTeamList() {
      var list = JSON.parse(JSON.stringify(this.doctorTeamList));
      list.unshift({
        teamCode: 'all',
        teamName: '全部',
      });
      return list;
    },
  },
  watch: {
    'formInline.parentDeptCode': function (value) {
      this.formInline.deptCode = 'all';
      this.departmentList = [{ deptCode: 'all', deptName: '全部' }];
      this.parentDepartmentList.forEach((item) => {
        if (item.deptCode === value) {
          this.departmentList = [
            ...this.departmentList,
            ...JSON.parse(JSON.stringify(item.children)),
          ];
        }
      });
    },
  },
  mounted() {
    this.getTeamList();
    this.getDepartment();
    this.getDoctorList();
  },
  methods: {
    ...mapActions('doctorTeam', ['queryList']),
    ...mapActions('basicDictionary', ['deptQueryList']),
    // 查询团队列表
    getTeamList() {
      this.queryList({});
    },
    // 分页方法调用
    handleSizeChange(currentPage) {
      this.pageInfo.size = currentPage;
      this.getDoctorList();
    },
    handleCurrentChange(currentPage) {
      this.pageInfo.page = currentPage;
      this.getDoctorList();
    },
    // 重置
    queryClear() {
      this.formInline = {
        // 查询条件
        doctorName: '',
        teamCode: 'all',
        parentDeptCode: 'all',
        deptCode: 'all',
      };
      this.pageInfo = {
        // 分页功能参数初始化
        page: 1,
        size: 10,
        total: 0,
      };
      this.getDoctorList();
    },
    queryData() {
      this.pageInfo.page = 1;
      this.getDoctorList();
    },
    // 分页查询问诊类型列表
    getDoctorList() {
      this.loading = true;
      const params = {
        ...JSON.parse(JSON.stringify(this.formInline)),
        page: {
          done: true,
          pageIndex: this.pageInfo.page,
          pageSize: this.pageInfo.size,
        },
      };
      params.teamCode = params.teamCode === 'all' ? '' : params.teamCode;
      params.parentDeptCode =
        params.parentDeptCode === 'all' ? '' : params.parentDeptCode;
      params.deptCode = params.deptCode === 'all' ? '' : params.deptCode;
      this.$api
        .queryDoctorList(params)
        .then((res) => {
          this.orderListData = res.data.data;
          this.pageInfo.total = Number(res.data.total); // 获取总共有多少条数据
          this.loading = false;
        })
        .catch(() => {});
    },
    // 导出列表
    /**
     * 列表中数据的操作方法
     * 0 - 查看
     * 1 - 编辑
     * 2 - 删除
     */
    dataOperation(item) {
      console.log(item, 'item');
      this.$router.push({
        path: '/rosterManage/revisionDetail',
        query: {
          detail: JSON.stringify(item),
          doctorId: item.id,
        },
      });
    },
    async getDepartment() {
      await this.deptQueryList();
      console.log(this.allDepartmentList, 'allDepartmentList');
      this.listToTree(this.allDepartmentList);
    },
    // 科室列表转树
    listToTree(oldArr) {
      this.parentDepartmentList = [];
      oldArr.forEach((element) => {
        const parentDeptCode = element.parentDeptCode
          ? element.parentDeptCode
          : null;
        if (parentDeptCode !== null) {
          oldArr.forEach((ele) => {
            if (ele.deptCode === parentDeptCode) {
              // 当内层循环的ID== 外层循环的parendId时，（说明有children），需要往该内层id里建个children并push对应的数组；
              if (!ele.children) {
                ele.children = [];
              }
              ele.children.push(element);
            }
          });
        }
      });
      oldArr = oldArr.filter(
        (ele) => !ele.parentDeptCode || ele.parentDeptCode === null
      ); // 这一步是过滤，按树展开，将多余的数组剔除；
      oldArr.unshift({ deptCode: 'all', deptName: '全部' });
      this.parentDepartmentList = oldArr;

      console.log(this.parentDepartmentList, '11111111111');
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 20px;
}

.width-96 {
  width: 96%;
}

.pagination-padding-r {
  padding-right: 60px;
}

.demo-input-suffix {
  padding-top: 30px;
}

body .el-table th.gutter {
  display: table-cell !important;
}

.padding-30 {
  padding-right: 56px;
  margin-top: 10px;
}

.circle {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 50%;

  /* 宽度和高度需要相等 */
}

.flex {
  display: flex;
}

.orderStatic {
  padding: 10px 0;
}

.labelStyle {
  width: 8%;
  height: 35px;
  margin-right: 1px;
  font-size: 14px;
  text-align: center;
}

.active {
  color: #eee;
  background-color: #409eff;
}
</style>
9
